.home2-container1 {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.home2-navbar-interactive {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
}
.home2-image {
  height: 2rem;
}
.home2-desktop-menu {
  flex: 1;
  display: flex;
  justify-content: space-between;
}
.home2-links1 {
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home2-text11 {
  margin-left: var(--dl-space-space-twounits);
}
.home2-text12 {
  margin-left: var(--dl-space-space-twounits);
}
.home2-text13 {
  margin-left: var(--dl-space-space-twounits);
}
.home2-text14 {
  margin-left: var(--dl-space-space-twounits);
}
.home2-link {
  margin-left: var(--dl-space-space-twounits);
}
.home2-buttons1 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home2-burger-menu {
  display: none;
}
.home2-icon10 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.home2-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: 32px;
  z-index: 100;
  position: absolute;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
}
.home2-nav {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home2-top {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.home2-logo {
  height: 2rem;
}
.home2-close-menu {
  display: flex;
  align-items: center;
  justify-content: center;
}
.home2-icon12 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.home2-links2 {
  flex: 0 0 auto;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
.home2-text17 {
  margin-bottom: var(--dl-space-space-unit);
}
.home2-text18 {
  margin-bottom: var(--dl-space-space-unit);
}
.home2-text19 {
  margin-bottom: var(--dl-space-space-unit);
}
.home2-text20 {
  margin-bottom: var(--dl-space-space-unit);
}
.home2-text21 {
  margin-bottom: var(--dl-space-space-unit);
}
.home2-buttons2 {
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home2-login2 {
  margin-right: var(--dl-space-space-twounits);
}
.home2-icon14 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.home2-icon16 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.home2-icon18 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.home2-container2 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home2-text22 {
  display: inline-block;
}
.home2-text23 {
  display: inline-block;
}
.home2-text24 {
  display: inline-block;
}
.home2-container4 {
  display: contents;
}
.home2-container6 {
  display: contents;
}
@media(max-width: 767px) {
  .home2-navbar-interactive {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home2-desktop-menu {
    display: none;
  }
  .home2-burger-menu {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .home2-text17 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .home2-text18 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .home2-text19 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .home2-text20 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .home2-text21 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
}
@media(max-width: 479px) {
  .home2-navbar-interactive {
    padding: var(--dl-space-space-unit);
  }
  .home2-mobile-menu {
    padding: 16px;
  }
}
